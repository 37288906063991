
import React from 'react';
import {ScrollRestoration, createBrowserRouter, Outlet } from "react-router-dom";
import PresGeneral from './PresGeneral';
import { Menu} from 'antd';
import { itemsMenu } from './menu';
import PageFaute from '../commun/PageFaute';
import Presentation from './PresGeneral';

//const CreationScrabble = React.lazy(() => import('../../jeux/reflexion/scrable/creationScrable'));
const PresMot = React.lazy(() => import('./PresMot'));
const PresCultureGenerale = React.lazy(() => import('./PresCultureGenerale'));
const PresAgeMental = React.lazy(() => import('./PresAgeMental'));
const PresCerebral = React.lazy(() => import('./PresCerebral'));
const PresGeographie = React.lazy(() => import('./PresGeographie'));
const PresConjugaison = React.lazy(() => import('./PresConjugaison'));
const PresReflexion = React.lazy(() => import('./PresReflexion'));
const PresFootball = React.lazy(() => import('./PresFootball'));
const PresYam = React.lazy(() => import('./PresYam'));


const JeuSurvivant = React.lazy(() => import('../../jeux/football/survivant/JeuSurvivant'));


const JeuCode = React.lazy(() => import('../../jeux/code/JeuCode'));
const MasterMot = React.lazy(() => import('../../jeux/masterMot/MasterJeu'));
const JeuDesordre = React.lazy(() => import('../../jeux/desordre/JeuDesordre'));
const JeuBoogle = React.lazy(() => import('../../jeux/boogle/JeuBoogle'));
const JeuChasse = React.lazy(() => import('../../jeux/chasse/JeuChasse'));
const JeuCache = React.lazy(() => import('../../jeux/cache/JeuCache'));
const JeuCoupe = React.lazy(() => import('../../jeux/coupe/JeuCoupe'));
const JeuPhraseErreur = React.lazy(() => import('../../jeux/phraseErreur/JeuPhraseErreur'));
const JeuTrou = React.lazy(() => import('../../jeux/trou/JeuTrou'));

const JeuPuzzleRotation = React.lazy(() => import('../../jeux/puzzleRotation/JeuPuzzleRotation'));
const JeuPuzzle = React.lazy(() => import('../../jeux/puzzle/JeuPuzzle'));
const JeuEnigme = React.lazy(() => import('../../jeux/enigme/JeuEnigme'));
const JeuMastermind = React.lazy(() => import('../../jeux/mastermind/JeuMastermind'));
const JeuBinero = React.lazy(() => import('../../jeux/binero/JeuBinero'));
const CreationPicross = React.lazy(() => import('../../jeux/picross/CreationPicross'));
const JeuPicross = React.lazy(() => import('../../jeux/picross/JeuPicross'));
const JeuFubuki = React.lazy(() => import('../../jeux/fubuki/JeuFubuki'));
//const CreationLigne = React.lazy(() => import('../../jeux/ligne/creationLigne'));
//const CreationPuzzleRotation = React.lazy(() => import('../../jeux/puzzleRotation/CreationPuzzleRotation'));
const JeuLigne = React.lazy(() => import('../../jeux/ligne/JeuLigne'));
const JeuBingo = React.lazy(() => import('../../jeux/bingo/JeuBingo'));
const JeuComptage = React.lazy(() => import('../../jeux/comptage/JeuComptage'));
const JeuMemoireMot = React.lazy(() => import('../../jeux/memoireMot/JeuMemoireMot'));
const JeuVitesse = React.lazy(() => import('../../jeux/vitesse/JeuVitesse'));
const JeuSimon = React.lazy(() => import('../../jeux/simon/JeuSimon'));
const JeuCalcul = React.lazy(() => import('../../jeux/calcul/JeuCalcul'));
const AgeMental = React.lazy(() => import('../../jeux/commun/AgeMental'));
const JeuCulture = React.lazy(() => import('../../jeux/culture/JeuCulture'));
const JeuGeographie = React.lazy(() => import('../../jeux/geographie/JeuGeographie'));
// const JeuQcmGeo = React.lazy(() => import('../../jeux/geographie/carteFacile/JeuCarteFacile'));
// const JeuFranceRegion = React.lazy(() => import('../../jeux/geographie/carteRegion/JeuCarteRegion'));
const JeuGeographieFacile = React.lazy(() => import('../../jeux/geographie/JeuGeographieFacile'));
const JeuCarteComplete= React.lazy(() => import('../../jeux/geographie/carteComplete/JeuCarteComplete'));
const JeuHumain= React.lazy(() => import('../../jeux/geographie/carteHumain/JeuHumain'));
const JeuFleuve= React.lazy(() => import('../../jeux/geographie/carteFleuve/JeuCarteFleuve'));
const JeuFrontiere= React.lazy(() => import('../../jeux/geographie/frontiere/JeuFrontiere'));
const JeuCarteOcean = React.lazy(() => import('../../jeux/geographie/carteOcean/JeuCarteOcean'));
const JeuCasePays = React.lazy(() => import('../../jeux/geographie/casePays/JeuCasePays'));
const JeuSudoku= React.lazy(() => import('../../jeux/reflexion/sudoku/JeuSudoku'));
const JeuDemineur= React.lazy(() => import('../../jeux/reflexion/demineur/JeuDemineur'));
const JeuPicrossReflexion= React.lazy(() => import('../../jeux/reflexion/picross/JeuPicross'));
const JeuLabyrinthe= React.lazy(() => import('../../jeux/reflexion/labyrinthe/JeuLabyrinthe'));
const JeuListeMots= React.lazy(() => import('../../jeux/reflexion/liste/ListeMots'));
const JeuVocImage= React.lazy(() => import('../../jeux/vocabulaire/images/JeuVocImage'));
const JeuIntrus= React.lazy(() => import('../../jeux/vocabulaire/intrus/JeuIntrus'));
const ConstructionMots= React.lazy(() => import('../../jeux/reflexion/mots/construction/ConstructionMots'));
const JeuMots= React.lazy(() => import('../../jeux/reflexion/mots/JeuMots'));
const JeuOrthographe= React.lazy(() => import('../../jeux/orthographe/mot/JeuMot'));
const JeuConnexion =  React.lazy(() => import('../../jeux/vocabulaire/connexion/JeuConnexion'));
const JeuScrable = React.lazy(() => import('../../jeux/reflexion/scrable/JeuScrable'));
const JeuYams= React.lazy(() => import('../../jeux/reflexion/yams/JeuYams'));
const JeuPassage = React.lazy(() => import('../../jeux/vocabulaire/passage/JeuPassage'));
const JeuEvaluation = React.lazy(() => import('../../jeux/evaluation/JeuEvaluation'));

const JeuDefinition = React.lazy(() => import('../../jeux/vocabulaire/definition/JeuDefinition'));
const JeuQcmVocabulaire = React.lazy(() => import('../../jeux/vocabulaire/qcmVocabulaire/JeuQcmVocabulaire'));
const JeuConjuguer = React.lazy(() => import('../../jeux/conjugaison/conjuguer/JeuConjuguer'));
const JeuListeTemps = React.lazy(() => import('../../jeux/conjugaison/liste/JeuListeTemps'));
const ClassementGeographie= React.lazy(() => import('../commun/ClassementGeographie'));
const PresLabyrinthe= React.lazy(() => import('../principal/PresLabyrinthe'));
const PresPicross= React.lazy(() => import('../principal/PresPicross'));
const PresDemineur= React.lazy(() => import('../principal/PresDemineur'));
const PresEval= React.lazy(() => import('../principal/PresEval'));
const PreRequis = React.lazy(() => import('../../jeux/evaluation/Prerequis'));
const PresSudoku= React.lazy(() => import('../principal/PresSudoku'));
const PresMotsCases = React.lazy(() => import('../principal/PresMotsCases'));
const PresListe = React.lazy(() => import('../principal/PresListe'));
const PresScrable = React.lazy(() => import('../principal/PresScrable'));
const PresOrthographe = React.lazy(() => import('./PresOrthographe'));
const PresVocabulaire = React.lazy(() => import('./PresVocabulaire'));
const PresCarte = React.lazy(() => import('./PresCarte'));
const PresDrapeau = React.lazy(() => import('./PresDrapeau'));
const PresCapitale = React.lazy(() => import('./PresCapitale'));
const PresHumain = React.lazy(() => import('./PresHumain'));
const CreationScrable = React.lazy(() => import('../../jeux/reflexion/scrable/creationScrable'));




const router = createBrowserRouter([
    {
      path: '/',
      element: <div><ScrollRestoration
      getKey={(location) => {
        return location.pathname;
      }}
      ></ScrollRestoration>
      <div className='menuHaut'><Menu items={itemsMenu} mode="horizontal"></Menu></div>
      <div className='margeEcran'><Outlet></Outlet></div></div>,
      errorElement : <PageFaute></PageFaute>,
      children: [
       
          {
            index : true,
            element: <PresGeneral />,
          },
          {
            path :"presentation",
            element: <PresGeneral />,
          },
          {
            path :"presorthographe",
            element: <PresOrthographe />,
          },
          {
            path :"presfootball",
            element: <PresFootball />,
          },
          {
            path :"presconjugaison",
            element: <PresConjugaison />,
          },
          {
            path :"presevaluation",
            element: <PresEval />,
          },
          {
            path :"presvocabulaire",
            element: <PresVocabulaire />,
          },
          {
            path :"presgeographie",
            element: <PresGeographie />,
          },
          
          {
            path: 'presentationcerebral',
            element: <PresCerebral/>,
          },
          {
            path :"pres-drapeaux",
            element: <PresDrapeau />,
          },
          
          {
            path: 'pres-capitale',
            element: <PresCapitale/>,
          }, {
            path :"pres-cartes",
            element: <PresCarte />,
          },
          
          {
            path: 'pres-corps-humain',
            element: <PresHumain/>,
          },
          {
            path: 'yam-presentation',
            element: <PresYam/>,
          },
          {
            path: 'prerequis',
            element: <PreRequis/>,
          },
          {
            path: 'sudoku-presentation',
            element: <PresSudoku/>,
          },
          {
            path: 'demineur-presentation',
            element: <PresDemineur/>,
          },
          {
            path: 'picross-presentation',
            element: <PresPicross/>,
          },
          {
            path: 'labyrinthe-presentation',
            element: <PresLabyrinthe/>,
          },
          {
            path: 'mots-a-caser-presentation',
            element: <PresMotsCases/>,
          },
          {
            path: 'mots-presentation',
            element: <PresListe/>,
          },
          {
            path: 'mots-scrable',
            element: <PresScrable/>,
          },
          {
            path: 'presagemental',
            element: <PresAgeMental/>,
          },
          {
            path: 'presreflexion',
            element: <PresReflexion/>,
          },
          {
            path: 'presCulture',
            element: <PresCultureGenerale/>,
          },
          {
            path: 'presMot',
            element: <PresMot/>,
          },
          {
            path: 'passage',
            element: <JeuPassage/>,
          },
          {
            path: 'definition',
            element: <JeuDefinition/>,
          },
          {
            path: 'intrus',
            element: <JeuIntrus/>,
          },
          {
            path: 'intrus3',
            element: <JeuIntrus/>,
          },
          {
            path: 'qcmvocabulaire',
            element: <JeuQcmVocabulaire/>,
          },

      
          {
            path: 'evaluation/:type',
            element: <JeuEvaluation/>,
          },

          {
            path: 'conjuguer',
            element: <JeuConjuguer/>,
          },
          {
            path: 'mastermot',
            element: <MasterMot/>,
          },
          {
            path: 'vocimage',
            element: <JeuVocImage/>,
            
          },
          {
            path: 'vocimage3',
            element: <JeuVocImage/>,
            
          },
          {
            path: 'orthographe',
            element: <JeuOrthographe/>,
            
          },
          {
            path: 'connexion',
            element: <JeuConnexion/>,
            
          },
          {
            path: 'connexion3',
            element: <JeuConnexion/>,
            
          },
          {
            path: 'scrable/:niveau',
            element: <JeuScrable/>,
            
          },
          {
            path: 'yams',
            element: <JeuYams/>,
            
          },
          {
            path: 'listemots',
            element: <JeuListeMots/>,
            
          },
          {
            path: 'desordre',
            element: <JeuDesordre/>,
            
          },
          {
            path: 'boogle',
            element: <JeuBoogle/>,
            
          },
          {
            path: 'chasse',
            element: <JeuChasse/>,
            
          },
          {
            path: 'cache',
            element: <JeuCache/>,
            
          },
          {
            path: 'coupe',
            element: <JeuCoupe/>,
            
          },
          {
            path: 'code',
            element: <JeuCode/>,
            
          },
          {
            path: 'phraseerreur',
            element: <JeuPhraseErreur/>,
            
          },
          {
            path: 'trou',
            element: <JeuTrou/>,
            
          },
          {
            path: 'rotation',
            element: <JeuPuzzleRotation/>,
            
          },
          {
            path: 'puzzle',
            element: <JeuPuzzle/>,
            
          },
          {
            path: 'enigme',
            element: <JeuEnigme/>,
            
          },
          {
            path: 'mastermind',
            element: <JeuMastermind/>,
            
          },
          {
            path: 'binero',
            element: <JeuBinero/>,
            
          },
          {
            path: 'picross',
            element: <JeuPicross/>,
            
          },
          {
            path: 'fubuki',
            element: <JeuFubuki/>,
            
          },
          {
            path: 'ligne',
            element: <JeuLigne/>,
            
          },
          {
            path: 'bingo',
            element: <JeuBingo/>,
            
          },
          {
            path: 'comptage',
            element: <JeuComptage/>,
            
          },
          {
            path: 'memoiremot',
            element: <JeuMemoireMot/>,
            
          },
          {
            path: 'vitesse',
            element: <JeuVitesse/>,
            
          },
          {
            path: 'simon',
            element: <JeuSimon/>,
            
          },
          {
            path: 'calcul',
            element: <JeuCalcul/>,
            
          },
          {
            path: 'agemental',
            element: <AgeMental/>,
            
          },
          {
            path: 'jeuculture',
            element: <JeuCulture/>,
            
          },
          {
            path: 'jeugeographie',
            element: <JeuGeographie/>,
            
          },
      

          {
            path: 'jeugeographiefacile',
            element: <JeuGeographieFacile/>,
            
          },
          {
            path: 'jeucartecomplete/:nom',
            element: <JeuCarteComplete/>,
            
          },
          {
            path: 'jeucarteocean/:nom',
            element: <JeuCarteOcean/>,
            
          },
          {
            path: 'jeuhumain/:nom',
            element: <JeuHumain/>,
            
          },
          {
            path: 'jeufleuve',
            element: <JeuFleuve/>,
            
          },
          {
            path: 'jeupays/:nom/:region',
            element: <JeuCasePays/>,
            
          },
          {
            path: 'jeufrontiere/:nom/:type',
            element: <JeuFrontiere/>,
            
          },
          {
            path: 'survivant/:numero',
            element: <JeuSurvivant/>,
            
          },
          {
            path: 'sudoku',
            element: <JeuSudoku/>,
          },

          {
            path: 'picrossreflexion',
            element: <JeuPicrossReflexion/>,
          },
          {
            path: 'demineur',
            element: <JeuDemineur/>,
          },
          {
            path: 'labyrinthe',
            element: <JeuLabyrinthe/>,
          },
          {
            path: 'motscases',
            element: <JeuMots/>,
          },
          {
            path: 'classementplateau',
            element: <ClassementGeographie/>,
          },
          // {
          //   path: 'classementtemps',
          //   element: <ClassementTemps/>,
          // },
          {
            path: 'listetemps',
            element: <JeuListeTemps/>,
          },

        
        
        
      ]
    }
   

  ]);
  


export {router}